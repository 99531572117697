import VideoScrubber from "../VideoScrubber";
import Controls0 from "./ai-artist";
import Controls4 from "./sorting";
import Controls6 from "./ifs";
import Controls7 from "./photo-op";
import Controls11 from "./style-transfer";
import Controls16 from "./stylegan";
import Controls18 from "./temple-photo-op";
import Controls19 from "./pong4";
import Controls21 from "./wind";
import Controls26 from "./maze-generation";
import Controls27 from "./life";
import Controls33 from "./hilbert";
import Controls35 from "./butterfly";
import Controls41 from "./bus-bunching";
import Controls54 from "./graduation";
const controls: Map<string, () => JSX.Element> = new Map([
  ["ai-artist", Controls0],
["grendel", VideoScrubber],
["sorting", Controls4],
["ifs", Controls6],
["photo-op", Controls7],
["pajama-gladiator", VideoScrubber],
["witchscat", VideoScrubber],
["style-transfer", Controls11],
["metro", VideoScrubber],
["sheriff", VideoScrubber],
["stylegan", Controls16],
["temple-photo-op", Controls18],
["pong4", Controls19],
["wind", Controls21],
["taijitu", VideoScrubber],
["cenote", VideoScrubber],
["maze-generation", Controls26],
["life", Controls27],
["owned", VideoScrubber],
["hilbert", Controls33],
["butterfly", Controls35],
["rams-horn", VideoScrubber],
["bus-bunching", Controls41],
["papa", VideoScrubber],
["studacc", VideoScrubber],
["stowaway", VideoScrubber],
["death", VideoScrubber],
["salt", VideoScrubber],
["graduation", Controls54],
["estefan", VideoScrubber]
]);
export default controls;
